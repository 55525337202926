<template>
  <v-app>
    <v-app-bar color="primary" class="header">
      <v-container class="container_principal">
        <v-row class="main-x0">
          <v-col cols="9" md="10" class="tituloPortada" align="center">
            <p class="main-x0-1">
              {{ $t("portada.bannerSuperior") }}
            </p>
          </v-col>
          <v-col cols="3" md="2" class="botonCambiarIdiomaPagina" align="right">
            <v-btn
              class="botonCambiarIdiomaPagina"
              v-if="this.$i18n.locale === 'gl'"
              @click="castellano()"
              ><span style="font-weight: lighter">ES</span> |
              <strong>GL</strong></v-btn
            >
            <v-btn
              class="botonCambiarIdiomaPagina"
              v-if="this.$i18n.locale === 'es'"
              @click="gallego()"
              ><strong>ES</strong> |
              <span style="font-weight: lighter">GL</span></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-container class="container_principal">
      <v-row>
        <v-col align="left" class="logo">
          <img src="@/assets/logo.svg" class="logo" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols md="5" order="2" order-md="1">
          <div class="main-x1-1" color="secondary">¡Fibra 1 Gb!</div>
          <div class="main-x1-2" color="secondary">32,90€/mes</div>
          <ul class="listaPortada">
            <li class="main-x1-li">
              {{ $t("portada.listaCaracteristicas.parrafo1") }}
            </li>
            <li class="main-x1-li">
              {{ $t("portada.listaCaracteristicas.parrafo2") }}
            </li>
            <li class="main-x1-li">
              {{ $t("portada.listaCaracteristicas.parrafo3") }}
            </li>
            <li class="main-x1-li">
              {{ $t("portada.listaCaracteristicas.parrafo4") }}
            </li>
          </ul>

          <v-row class="main-x2" style="padding-top: 40px">
            <v-col cols="12" sm="3">
              <v-btn color="primary" elevation="4" @click="irPedido" x-large
                >{{ $t("portada.botonQueroa") }}
              </v-btn>
            </v-col>

            <v-col cols="12" sm="2">
              <botonComprobarCobertura />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols md="7" order="1" order-md="2">
          <v-img
            max-width="800"
            src="@/assets/img/router_huawei_ld.webp"
          ></v-img>
        </v-col>
      </v-row>

      <br /><br />

      <v-row>
        <v-col>
          <div class="main-x3">
            <p>
              {{ $t("portada.textoEngadirFibra.parte1") }}
              <span style="color: #b7bf10">Fibra 100Mb </span
              ><span>{{ $t("portada.textoEngadirFibra.parte2") }}</span
              ><span style="color: #b7bf10"> 21,90€/mes </span
              ><span
                style="cursor: pointer"
                @click="irPedido"
                class="button-pedido-launch main-x3-3"
                >{{ $t("portada.textoEngadirFibra.parte3") }}</span
              >
            </p>
          </div>
        </v-col>
      </v-row>

      <v-row class="main-x4-row">
        <v-col>
          <div class="main-x4-1">
            <p>
              {{ $t("portada.textoOpcionFibra.parte1") }}
              <span style="color: #b7bf10">
                {{ $t("portada.textoOpcionFibra.parte2") }}
              </span>
            </p>
          </div>
        </v-col>
      </v-row>
      <!-- Zona de Caracteristicas -->

      <v-row>
        <v-col cols="2" sm="1" md="1" offset-sm="2" offset="1">
          <v-img contain max-width="40" src="@/assets/img/o1.svg"></v-img>
        </v-col>
        <v-col cols="8" sm="8" md="7">
          <div>
            <p class="main-x5-2">
              {{ $t("portada.caracteristicas.parrafo1") }}
            </p>
            <p class="main-x5-3">
              {{ $t("portada.caracteristicas.parrafo2") }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" sm="1" md="1" offset-sm="2" offset="1">
          <v-img contain max-width="40" src="@/assets/img/o2.svg"></v-img>
        </v-col>
        <v-col cols="8" sm="8" md="7">
          <div class="">
            <p class="main-x5-2">
              {{ $t("portada.caracteristicas.parrafo3") }}
            </p>
            <p class="main-x5-3">
              {{ $t("portada.caracteristicas.parrafo4") }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" sm="1" md="1" offset-sm="2" offset="1">
          <v-img contain max-width="40" src="@/assets/img/o3.svg"></v-img>
        </v-col>
        <v-col cols="8" sm="8" md="7">
          <div class="">
            <p class="main-x5-2">
              {{ $t("portada.caracteristicas.parrafo5") }}
            </p>
            <p class="main-x5-3">
              {{ $t("portada.caracteristicas.parrafo6") }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" sm="1" md="1" offset-sm="2" offset="1">
          <v-img contain max-width="40" src="@/assets/img/o4.svg"></v-img>
        </v-col>
        <v-col cols="8" sm="8" md="7">
          <div class="">
            <p class="main-x5-2">
              {{ $t("portada.caracteristicas.parrafo7") }}
            </p>
            <p class="main-x5-3">
              {{ $t("portada.caracteristicas.parrafo8") }}
            </p>
          </div>
        </v-col>
      </v-row>
      <div class="main-x6">
        <v-btn
          class="button-pedido-launch"
          color="primary"
          @click="irPedido"
          elevation="2"
          x-large
          >{{ $t("portada.botonInteresame") }}
        </v-btn>
        <botonComprobarCobertura />
      </div>
      <!-- Bloque de texto é pulpo -->
      <v-row class="main-x7-row">
        <v-col cols class="main-x7">
          <div>
            <h1 class="main-x7-1">
              {{ $t("portada.textoPulpo.parte1") }}
            </h1>
            <h1 class="main-x7-2">
              {{ $t("portada.textoPulpo.parte2") }}
            </h1>
          </div>
        </v-col>
      </v-row>
      <!-- Bloque de texto é pulpo -->
      <!-- Bloque de precios -->
      <v-row>
        <v-col cols="12" sm="4">
          <div class="main-x8-1">
            <div class="main-x8 titulo">
              {{ $t("portada.precios.nombreTarifa") }} <br />
              básica
            </div>
            <div class="main-x8 detalle">
              {{ $t("portada.precios.llamadasIlimitadas") }}
              <br />Cobertura Movistar
            </div>
            <div class="main-x8 gb">+ 20GB</div>
            <div class="main-x8 img" align="center">
              <v-img
                contain
                src="@/assets/img/fibra_botella.svg"
                max-width="120"
                max-height="120"
              ></v-img>
            </div>
            <div class="main-x8 pvp">+ 11,75€/mes</div>
            <div class="main-x8 order">
              <v-btn
                class="button-pedido-launch"
                color="primary"
                @click="irPedido"
                elevation="2"
                x-large
                >{{ $t("portada.botonQueroa") }}
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="main-x8-1">
            <div class="main-x8 titulo">
              {{ $t("portada.precios.nombreTarifa") }}<br />
              media
            </div>
            <div class="main-x8 detalle">
              {{ $t("portada.precios.llamadasIlimitadas") }}
              <br />Cobertura Movistar
            </div>
            <div class="main-x8 gb">+ 40GB</div>
            <div class="main-x8 img" align="center">
              <v-img
                contain
                src="@/assets/img/fibra_cangrexo.svg"
                max-width="120"
                max-height="120"
              ></v-img>
            </div>
            <div class="main-x8 pvp">+ 15,75€/mes</div>
            <div class="main-x8 order">
              <v-btn
                class="button-pedido-launch"
                color="primary"
                @click="irPedido"
                elevation="2"
                x-large
                >{{ $t("portada.botonQueroa") }}
              </v-btn>
            </div>
          </div>
          <div class="main-x6">
            <botonComprobarCobertura />
          </div>
        </v-col>
        <v-col cols="12" sm="4" style="z-index: 10">
          <div class="main-x8-1">
            <div class="main-x8 titulo">
              {{ $t("portada.precios.nombreTarifa") }} <br />

              alta
            </div>
            <div class="main-x8 detalle">
              {{ $t("portada.precios.llamadasIlimitadas") }}<br />Cobertura
              Movistar
            </div>
            <div class="main-x8 gb">+ 60GB</div>
            <div class="main-x8 img" align="center">
              <v-img
                contain
                src="@/assets/img/fibra_rizon2.svg"
                max-width="120"
                max-height="120"
              ></v-img>
            </div>
            <div class="main-x8 pvp">+ 19,75€/mes</div>
            <div class="main-x8 order">
              <v-btn
                class="button-pedido-launch"
                color="primary"
                @click="irPedido"
                elevation="2"
                x-large
                >{{ $t("portada.botonQueroa") }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- Bloque de precios-->
      <!-- Bloque de Chamamoste
      <v-row align="center" class="main-x9">
        <v-col cols md="5" offset-md="1">
          <div class="main-x9-1">
            <p>
              {{ $t("portada.textoChamamoste.parte1") }}
              <br />
              <span style="color: #b7bf10">
                {{ $t("portada.textoChamamoste.parte2") }}</span
              >
            </p>
          </div>
        </v-col>
        <v-col md="5">
          <div class="main-x9-3" align="center">
            <v-form id="formulario-chamamoste">
              <v-text-field
                class="input_contacta"
                v-model="telefono"
                :rules="rulesMovil"
                label="Número de Teléfono"
                solo
                dense
              ></v-text-field>
              <v-checkbox v-model="checkbox" color="white">
                <template v-slot:label>
                  <div style="color:white;font-size:15px;padding-top:16px">
                    <template>
                      <p style="color: white">
                        {{ $t("portada.politicaPrivacidad.parte1") }}
                        <router-link style="color: white" to="aviso-legal">
                          {{
                            $t("portada.politicaPrivacidad.parte2")
                          }}</router-link>
                      </p>
                    </template>
                  </div>
                </template>
              </v-checkbox>
              <v-btn
                style="color: white"
                v-if="canEnviar"
                small
                outlined
                @click="formContacto()"
                >Enviar</v-btn
              >
              <v-btn disabled v-if="!canEnviar" small outlined>Enviar</v-btn>
            </v-form>
          </div>
        </v-col>
      </v-row>
      Bloque de Chamamoste -->
      <!-- Bloque de Ainda por riba -->
      <v-row class="main-x10-row"> </v-row>
      <!-- Bloque de Ainda por riba -->
      <!-- Bloque de Teléfono Fixo -->
      <v-row align="center">
        <v-col>
          <div class="main-x11">
            <p>
              {{ $t("portada.textoEngadirFixo.parte1") }}
              <span style="color: #b7bf10">{{
                $t("portada.textoEngadirFixo.parte2")
              }}</span>
            </p>
          </div>
        </v-col>
      </v-row>
      <div class="main-x12">
        <div class="main-x12-1">
          {{ $t("portada.bannerChamadas.chamadas") }}
          <span class="main-x12-1-1">ilimitadas </span
          >{{ $t("portada.bannerChamadas.deFixoAFixo") }}
        </div>
        <img class="main-x12-1-2" src="@/assets/img/fibra_mais.svg" />
        <div class="main-x12-2-1">
          <span style="color: #b7bf10; font-size: 2.2em">100</span>
          min<br />
          {{ $t("portada.bannerChamadas.deFixoAMobil") }}
        </div>
        <img class="main-x12-2" src="@/assets/img/fibra_igual.svg" />
        <div
          style="cursor: pointer"
          class="button-pedido-launch main-x12-3"
          @click="irPedido"
        >
          + 6€/mes
        </div>
      </div>
      <div>
        <v-snackbar
          v-model="snackbar"
          :bottom="true"
          :right="true"
          :timeout="snack_time"
          :color="snack_color"
        >
          {{ snack_text }}
          <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
        </v-snackbar>
      </div>
    </v-container>
    <!-- Footer -->
    <FooterView />
    <!-- Footer -->
  </v-app>
</template>

<script>
export default {
    name: 'App',
    components: {
        FooterView: () => import('./footer.vue'),
        botonComprobarCobertura: () => import('./botonComprobarCobertura.vue')
    },
    data: () => ({
        espera: 6000,
        snackbar: false,
        snack_color: '',
        snack_text: '',
        snack_time: -1,
        url: process.env.VUE_APP_API_URL,
        verForm: true,
        telefono: '',
        checkbox: false,
        regexpMovil: /^(\+34|0034|34)?[ .-]*(6|7|8|9)[ .-]*(\d[ .-]*){8}$/,
        rulesMovil: [
            (v) =>
                this.regexpMovil.test(v) || 'Inserta un número de teléfono válido.'
        ]
    }),
    methods: {
        go (msg, color, time) {
            this.snack_time = time
            this.snack_text = msg
            this.snack_color = color
            this.snackbar = true
        },
        castellano () {
            this.$i18n.locale = 'es'
        },
        gallego () {
            this.$i18n.locale = 'gl'
        },
        formContacto () {
            const contact_form = {
                numero: this.telefono
            }
            const goSendForm = async () => {
                const config = {
                    timeout: this.espera,
                    headers: {
                        Accept: 'application/json'
                    }
                }
                try {
                    return await this.$axios.post(
                        this.url + 'contacto/',
                        contact_form,
                        config
                    )
                } catch (error) {
                    if (error.response.status === 422) {
                        this.go(this.$t('portada.politicaPrivacidad.error'), 'error')
                    }
                }
            }
            const processgoSendFormResult = async () => {
                const sendForm_result = await goSendForm()
                if (sendForm_result) {
                    if (sendForm_result.data.success === true) {
                        this.go(this.$t('portada.politicaPrivacidad.success'), 'primary')
                    } else {
                        this.go(this.$t('portada.politicaPrivacidad.error'), 'error')
                    }
                }
            }
            processgoSendFormResult()
        },

        irPedido () {
            this.$store.dispatch('setdatosPortabilidadesValidados', true)
            this.$store.dispatch('setfibraPreseleccionada', true)

            this.$router.push('/pedido')
        }
    },
    computed: {
        canEnviar: function () {
            return this.regexpMovil.test(this.telefono) && this.checkbox === true
        }
    }
}
</script>
